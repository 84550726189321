import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
//import Header from "./Header";
//import Footer from "./Footer";
import Font from "../Style/font.css";
import ThanksIMG from "../images/thanks.svg";
import SuperImage from 'super-image';
import Button from '@material-ui/core/Button';


const styles = theme => ({

  pcDrawer: {
    //600pxより大きい場合表示
    display:'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  main:{
    width:'100%',
    marginRight:'auto',
    marginLeft:'auto',
    height:'100%',
    textAlign:'center',
  },
  area:{
    display:'inline-block',
    position:'relative',
    verticalAlign:'top',
    width:'100%',
    backgroundColor:'#0F56A8',
    marginTop:'-64px',
  },
  ill_area:{

  },

  ill_image:{
    height:'302px',
    display: 'block',
    width:'100%',
  },
  thanks_msg_bold:{
    display: 'block',
    fontSize:'28px',
    fontWeight:'700',
    textAlign:'center',
    fontFamily:'Noto Sans Japanese',
    color:'#333333',
  },
  thanks_msg_regular:{
    display: 'block',
    fontSize:'20px',
    textAlign:'center',
    fontFamily:'Noto Sans Japanese',
    color:'#000000',
    opacity: '0.5',
  },

  button_area:{
    width:'100%',
  },

  detail_button:{
    backgroundColor:'#0F56A8',
    color:'#ffffff',
    width:'200px',
    fontSize:'14px',
    borderColor:'#0f56A8',
    borderStyle:'none',
    fontFamily:'Noto Sans Japanese',
    textAlign:'center',
  },
  top_button:{
    backgroundColor:'#ffffff',
    color:'#0F56A8',
    width:'200px',
    fontSize:'14px',
    borderColor:'#0f56A8',
    borderStyle:'none',
    textAlign:'center',
  },

  //テキストボックスの大きさ(中)
  text_width_120px:{
    width:'120px',
    margin:'0px'
  },
  text_width_150px:{
    width:'150px',
    margin:'0px'
  },
  text_width_170px:{
    width:'170px',
    margin:'0px'
  },
  text_width_280px:{
    width:'280px',
    margin:'0px'
  },
  text_width_300px:{
    width:'300px',
    margin:'0px'
  },
  //テキストボックスの大きさ(大)
  text_width_640px:{
    width:'640px',
    margin:'0px',
  },
  //テキストボックスの大きさ(中)
  text_picker_field:{
    width:'80px',
    margin:'0px',
  },
  //テキストボックスの大きさ(小)
  text_small_field:{
    width:'50px',
    margin:'0px'
  },

  //コンテンツ間の空間（横）
  marginLeft_16px:{
    marginLeft:'16px'
  },
  marginLeft_40px:{
    marginLeft:'40px'
  },
  //コンテンツ間の空間（縦）
  marginBottom_8px:{
    marginBottom:'8px',
  },
  marginBottom_16px:{
    marginBottom:'16px',
  },
  marginBottom_24px:{
    marginBottom:'24px',
  },
  marginBottom_32px:{
    marginBottom:'32px',
  },
  marginBottom_64px:{
    marginBottom:'64px',
  },
  marginBottom_96px:{
    marginBottom:'96px',
  },
});

/*
  プライバシーポリシー
*/
class ThanksEA extends React.Component {
  /////////////////////////////////////////
　//処理概要：
　//コンストラクタ、
　//
　/////////////////////////////////////////
  constructor(props){
    //thisを利用するため、かつthis.propsを定義するため
    super(props);
    this.handleOnclick=this.handleOnclick.bind(this);
  }
  handleOnclick(){
    var id = "取得したURLのid=以降の文字列を取得する";
    var id = window.location.search.substring(1).split('=');
    if(typeof id[1] == "undefined"){
      id[1] = "";
    }
    if(id[1] == ""){
      this.props.history.push('/ea_form');
    }else{
      this.props.history.push('/ea_form?id='+id[1]+'='+id[2]);
    }
  }
  render() {

    const { classes } = this.props;

    const pcDrawer = (
    	<div className={classes.pcDrawer}>
        <div className={classes.main}>
        <div className={classes.marginBottom_64px} />
          <div className={classes.ill_area} >
            <div className={classes.marginBottom_64px} />
            <SuperImage
              src={ThanksIMG}
              className={classes.ill_image}
            />
            <div className={classes.marginBottom_64px} />
            <div className={classes.thanks_msg_bold} >
              申請ありがとうございます。
            </div>
            <div className={classes.marginBottom_16px} />
            <div className={classes.thanks_msg_regular} >
              メールを送信致しました。
            </div>
            <div className={classes.thanks_msg_regular} >
            迷惑メールに入っている可能性もございます。
            </div>
            <div className={classes.thanks_msg_regular} >
            ご確認の程よろしくお願い致します。
            </div>                  
            <div className={classes.marginBottom_64px} />
            <div className={classes.button_area} >
              <Button variant="contained" className={classes.detail_button} onClick={this.handleOnclick} >
                申請画面に戻る
              </Button>
            </div>
            <div className={classes.marginBottom_32px} />
          </div>
        </div>
      </div>
    );

    return (
        <div className={Font.body}>
            {pcDrawer}
        </div>
    );
  }
}

ThanksEA.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ThanksEA);
