import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
//import Header from "./Header";
//import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';
import TextBox from './TextBox';
import Button from '@material-ui/core/Button';
import Validation from './Validation';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import axios from 'axios';
import Font from "../Style/font.css";
import _ from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuperImage from 'super-image';
import NineTailsIMG from "../images/NINETAILS_icon1.png";
//import { useMediaQuery } from "react-response";


const styles = theme => ({

  pcDrawer: {
    //600px~960pxの範囲
    display:'block',
    //600px~960pxを除く範囲
    [theme.breakpoints.up('sm')]: {
      display: 'block',
//        minWidth:'1300px',
//        maxWidth:'1300px',
    }
  },
  title_area:{
    height: '36px',
    maxWidth:'750px',
    //position: 'relative',
    //left:'2%',
    margin: '0px auto 40px auto',
  },
  title_text:{
    color:'#32325D',
    height:'auto',
    textAlign: 'left',
    padding: '0px 0px 0px 10px',
    fontSize:'24px',
    fontWeight:'700',
    fontFamily: 'Noto Sans Japanese',
    display:'inline-block',
    verticalAlign:'middle',
    //margin: '0px 0px 0px 10px',
  },

  title_text_svg:{
    fill:'#BCC4D9',
    height:'auto',
    textAlign: 'left',
    display:'inline-block',
    verticalAlign:'middle',
  },  
  main_area: {
    border: "solid 2px #329eff",
    borderRadius: "20px",
    padding: "8px",
    margin: "0px",
  },
  input_area: {
    textAlign: 'left',
    height: '100%',
    minHeight:'55px',
    maxWidth:'750px',
    margin: '0px auto 24px auto',
    //position: 'relative',
  },
  input_item_text: {
    color:'#32325D',
    textAlign: 'left',
    padding: '10px 0px 0px 10px',
    fontSize:'14px',
    fontWeight:'600',
    fontFamily:'Noto Sans Japanese',
    display:'block',
    verticalAlign: 'middle',
  },
  block:{
    display:'block',
    padding: '10px 0px 10px 0px',
    fontSize:'14px',
    fontWeight:'400',
  },

  input_item_text_send: {
    width:'250px',
    fontFamily:'Noto Sans Japanese',
    fontWeight:'400',
    fontSize:'14px',
    display:'inline',
    verticalAlign: 'middle',
    
  },
  //テキストボックスの大きさ
  text_width_500px:{
    width:'500px',
    margin:'0px',
    fontFamily:'Noto Sans Japanese',
    verticalAlign: 'middle',
    padding: '10px 2px 2px 10px',
  },
  text_width_300px:{
    width:'300px',
    margin:'0px',
    fontFamily:'Noto Sans Japanese',
    verticalAlign: 'middle',
    padding: '10px 2px 2px 10px',
  },  
  button_area:{
    //padding: '0 auto 0 auto',
    //position: 'relative',
    //left:'4%',
    //float: 'left',
    
    //margin: '0px auto 24px auto',
    
    //right:'32px',
    //bottom: '8px',
    //margin: '0px 0px 24px 0px',    
  },

  button_search:{
    fontFamily:'Noto Sans Japanese',
    fontSize:'14px',
    fontWeight:'600',

  },
  //管理者画面モバイル不要
  mobileDrawer: {
      //600pxより小さい場合表示
      display:'flex',
      background:'#FFEDB3',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
        background:'#FFEDB3',
      },
    },
  //コンテンツ間の空間（縦）
  marginBottom_100px:{
    marginBottom:'30px',
  },
  marginBottom_32px:{
    marginBottom:'32px',
  },
  dialog_actiton:{
    display:'inline',
    verticalAlign: 'middle',
    marginLeft:'25px',
  },
  dialog_font:{
    fontFamily:'Noto Sans Japanese',
  },
  checkbox:{
    display:'inline-block',
    marginLeft:'10px',
  },
  checkbox_label:{
    fontFamily:'Noto Sans Japanese', 
    fontSize:'14px',
  },
  checkbox_label_no:{
    fontFamily:'Noto Sans Japanese', 
    fontSize:'14px',
    color:'red',
  },
  text_ea_kind:{
    fontFamily:'Noto Sans Japanese', 
    fontSize:'12px',
    color:'blue',
  },
  ill_image:{
    height:'100px',
    width:'100px',
  },
  ill_image_selected:{
    height:'100px',
    width:'100px',
    border: 'double 1px orange', 
    borderRadius: '8px',    
  },  
  inline:{
    display:'inline-block',
    width:'200px',
  },  
});

const validation = {
  accountNumber: {
    required: true,
    maxLength: 255,
    halfNum:true,
  },
  mailaddress: {
    required: true,
    type: 'email',
    maxLength: 255,
  },
  userName: {
    required: true,
    type: 'text',
    maxLength: 255,
  },
};


class RequestFormCalculator extends React.Component {

  constructor(props) {
    super(props);
    //初期値
    this.state = {
      userValue: [],
      checked_yes:false,
      checked_no:false,
      checked_standard_yes:false,
      checked_standard_no:false,
      checked_ea_mashimashigf:false,
      checked_ea_ninetails:false,
      error_message:[],
      errorFlgAccountNumber:false,
      errorFlgLastName:false,
      errorFlgLineName:false,
      errorFlgMailAddress:false,
      confirmAdd:false,
      size:{
        width:0,
        height:0,
      },
    };
    
    this.handleChangeItem=this.handleChangeItem.bind(this);
    this.handleChangeCheck=this.handleChangeCheck.bind(this);
    this.closeConfirmAdd=this.closeConfirmAdd.bind(this);
    this.handleOpenConfirmAdd=this.handleOpenConfirmAdd.bind(this);
    this.handleCheckDB=this.handleCheckDB.bind(this);
    this.handleCheckItem=this.handleCheckItem.bind(this);

  }
  ref = React.createRef();

  handleWindowResize = _.debounce(() => {
    const size = this.ref.current.getBoundingClientRect();
    this.setState({ size });
  });


  /////////////////////////////////////////
  //処理名： componentDidMount
  //処理概要： 検索結果の取得
  //
  //In：　検索条件パラメータ
  //Out
  /////////////////////////////////////////
  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  handleChangeCheck(e){
    if(e.target.value === "checkedYes" && this.state.checked_yes === true){
      this.setState({
        checked_yes:false,
      })
    }else if(e.target.value === "checkedYes" && this.state.checked_yes === false){
      this.setState({
        checked_yes:true,
      })      
    }
    if(e.target.value === "checkedNo" && this.state.checked_no === true){
      this.setState({
        checked_no:false,
      })
    }else if(e.target.value === "checkedNo" && this.state.checked_no === false){
      this.setState({
        checked_no:true,
      })
    }

    if(e.target.value === "checkedStandardYes" && this.state.checked_standard_yes === true){
      this.setState({
        checked_standard_yes:false,
      })
    }else if(e.target.value === "checkedStandardYes" && this.state.checked_standard_yes === false){
      this.setState({
        checked_standard_yes:true,
      })      
    }
    if(e.target.value === "checkedStandardNo" && this.state.checked_standard_no === true){
      this.setState({
        checked_standard_no:false,
      })
    }else if(e.target.value === "checkedStandardNo" && this.state.checked_standard_no === false){
      this.setState({
        checked_standard_no:true,
      })
    }    
  }
  handleChangeItem(e){
    var validationResult = '';
    
    if(e.target.dataset.validation !== undefined){
      const rule = JSON.parse(e.target.dataset.validation);
      validationResult = Validation(e.target.value, rule);
    }
    // 入力値
    var joined = {target: e.target.name, value: e.target.value};
    var value = this.state.userValue;
    var index = value.findIndex(({target}) => target === e.target.name);
    if(index >= 0){
      value[index].value = e.target.value;
    }else{
      value.push(joined);
    }
    // バリデーションエラー時
    if(validationResult !== ''){
      // エラーメッセージ
      var error_joined = {target: e.target.name, mes: validationResult};
      var error = this.state.error_message;
      var error_index = error.findIndex(({target}) => target === e.target.name);
      if(error_index >= 0){
        error[error_index].mes = validationResult;
      }else{
        error.push(error_joined);
      }

      this.setState({error_message:error, userValue:value});
    // バリデーションエラーでない時
    }else{
      // エラーメッセージ
      var array = this.state.error_message;
      var array_index = array.findIndex(({target}) => target === e.target.name);
      if(array_index >= 0){
        array.splice(array_index, 1);
      }
      this.setState({error_message: array, userValue:value});
    }
    //DebugLog
    console.log("this.state.userValue:",this.state.userValue);
    //console.log("this.state.error_message:",this.state.error_message);
  };

  handleCheckItem(){
    console.log("handleCheckItem()に入ってきた");
    const isRequired=[
      {target:'account_number',rule:validation.accountNumber},
      {target:'line_name',rule:validation.userName},
      {target:'mail_address',rule:validation.mailaddress},
    ];
    var input_value=this.state.userValue;
    var error_value = this.state.error_message;
    var noErrorflag=true;
    var errorFlgAccountNumber = false;
    var errorFlgLastName = false;
    var errorFlgLineName = false;
    var errorFlgMailAddress = false;
    var mashimashigf = this.state.checked_ea_mashimashigf;
    var ninetails = this.state.checked_ea_ninetails;    

    isRequired.forEach((f)=>{
      //input_valueに存在しているか
      var idx = input_value.findIndex(({target}) => target === f.target);
      console.log("target:" ,f.target,"idx:",idx);
      //error_massageが存在しているか
      var error_index = error_value.findIndex(({target}) => target ===f.target);
      console.log("target:" ,f.target,"error_index:",error_index);

      //バリュー存在していない
      if(idx<0){
        //エラーメッセージがない場合
        if(error_index < 0){
          //error_valueに追加
          console.log("入力されていないかつ、エラーメッセージもない");
          //エラーメッセージをセットする
          var error ='必須項目です';
          var error_joined = {target: f.target, mes: error};
          error_value.push(error_joined);
          noErrorflag=false;
          if(f.target === 'account_number'){
            errorFlgAccountNumber = false;
            this.setState({
              error_message:error_value,
            });
          }else if(f.target === 'line_name'){
            errorFlgLineName = false;
            this.setState({
              error_message:error_value,
            });
          }else if(f.target === 'mail_address'){
            errorFlgMailAddress = false;
            this.setState({
              error_message:error_value,
            });
          }
        }else{
          //error_messageが存在していない　状況的には起こりえないはず
          console.log("入力されていないかつ、エラーメッセージある");
          noErrorflag=false;
          if(f.target === 'account_number'){
            errorFlgAccountNumber = false;
          }else if(f.target === 'line_name'){
            errorFlgLineName = false;
          }else if(f.target === 'mail_address'){
            errorFlgMailAddress = false;
          }
        }
      }else{
        //エラーメッセージがない場合
        if(error_index < 0){
          console.log("入力されている、エラーメッセージもない");
          noErrorflag = true;
          //ここでDB叩く
          if(f.target === 'account_number'){
            errorFlgAccountNumber = true;
          }else if(f.target === 'line_name'){
            errorFlgLineName = true;
          }else if(f.target === 'mail_address'){
            errorFlgMailAddress = true;
          }
        }else{
          console.log("入力されている、エラーメッセージはある");
          noErrorflag=false;
          if(f.target === 'account_number'){
            errorFlgAccountNumber = false;
          }else if(f.target === 'line_name'){
            errorFlgLineName = false;
          }else if(f.target === 'mail_address'){
            errorFlgMailAddress = false;
          }   
        }
      }
    });
    var eaflg = true;
    if(!errorFlgAccountNumber || !errorFlgLineName|| !errorFlgMailAddress){
      noErrorflag = false;
    }else{
      return noErrorflag
    }
    
  };

  closeConfirmAdd(){
    this.setState({
      confirmAdd: false
    });
  };

  handleOpenConfirmAdd(){
    this.setState({
      confirmAdd: true
    });
  };

  handleCheckDB() {
    if(this.handleCheckItem()){
      var accountnumber = this.state.userValue.find(item => item.target === "account_number").value;
      //var name = this.state.userValue.find(item => item.target === "last_name").value;
      var line = this.state.userValue.find(item => item.target === "line_name").value;
      var mail = this.state.userValue.find(item => item.target === "mail_address").value;
      var mashimashigf = this.state.checked_ea_mashimashigf;
      var ninetails = this.state.checked_ea_ninetails;
      console.log("this.handleCheckItem():",this.handleCheckItem());
      console.log("mashimashigf:",mashimashigf);
      console.log("ninetails:",ninetails);
      var baseUrl = window.location.origin;
      var params = new FormData();
      params.append('last_name', line);
      params.append('line_name', line);
      params.append('mail_address', mail);
      params.append('account_number', accountnumber);
      var arg = new Object;
      var pair=window.location.search.substring(1).split('&');
      for(var i=0;pair[i];i++) {
        var kv = pair[i].split('=');// kvはkey-value
        arg[kv[0]]=kv[1];
        } 
      if(typeof arg.id == "undefined"){
        arg.id = "";
      }
      if(typeof arg.uid == "undefined"){
        arg.uid = "";
      }  
      console.log("arg.id:",arg.id);
      console.log("arg.uid:",arg.uid);

      params.append('id',arg.id);
      params.append('uid',arg.uid);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      axios.post(baseUrl+"/AccountMGT/server/checkuserformeaLotTool.php",params)
      .then(res => {
        //this.closeConfirmAdd();
        if(arg.uid == ""){
          this.props.history.push('/thankslottool');
        }else{
          this.props.history.push('/thankslottool?id='+arg.id+'&uid='+arg.uid);
        }
      })
      .catch(error => {
        console.log(error);
        //未実装　404画面へ飛ばす
      });
    }else{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });      
      console.log("flaseが含まれます");
      this.closeConfirmAdd();
    }
  }

  render() {

    const { classes} = this.props;

    //const isMobileScreen = useMediaQuery({ query: '(max-width: 560px)'});

    //PC,タブレット表示
    const pcDrawer=(

      
      <div className={classes.pcDrawer}>

        <div className={classes.marginBottom_32px}/>
        <Paper elevation={0} className={classes.main_area}>
        <div className={classes.title_area}>
          <MailOutlineIcon className={classes.title_text_svg} fontSize='large'/>
          <div className={classes.title_text}>資金管理ツールの利用申請</div>
        </div>
        <Paper elevation={0} className={classes.input_area}>
        <span className={classes.text_ea_kind}>
          {'　'}<br/>
          {'　この度は、資金管理ツールをご利用頂きありがとうございます。'}<br/>
          {'　各種情報を入力し無料ツールをお受け取り下さい。'}<br/>
        </span>
        </Paper>
        <Paper elevation={0} className={classes.input_area}>
          <div className={classes.marginBottom_100px}>
          <div ref={this.ref}/>
            <div className={classes.input_item_text}>①LINE名（必須）</div>
            <div className={classes.block}>
              <TextBox
                className={this.state.size.width > 500 ? classes.text_width_500px:classes.text_width_300px}
                id="lineName"
                name="line_name"
                placeholder="LINE名*"
                validation={validation.userName}
                onChange={this.handleChangeItem}
                value={this.state.userValue.find(item => item.target === "line_name") === undefined ? '' : this.state.userValue.find(item => item.target === "line_name").value}
                errorMessage={this.state.error_message.find(item => item.target === "line_name") === undefined ? '' : this.state.error_message.find(item => item.target === "line_name").mes}
              />
            </div>
          </div>
        </Paper>
        <Paper elevation={0} className={classes.input_area}>
          <div className={classes.marginBottom_100px}>
            <div className={classes.input_item_text}>②口座番号（必須）※今回新しく開設した口座番号を入力ください</div>
            <div className={classes.block}>
            <TextBox
              id="AccountNumber"
              name="account_number"
              placeholder="口座番号*"
              className={this.state.size.width > 500 ? classes.text_width_500px:classes.text_width_300px}
              variant="outlined"
              validation={validation.accountNumber}
              onChange={this.handleChangeItem}
              value={this.state.userValue.find(item => item.target === "account_number") === undefined ? '' : this.state.userValue.find(item => item.target === "account_number").value}
              errorMessage={this.state.error_message.find(item => item.target === "account_number") === undefined ? '' : this.state.error_message.find(item => item.target === "account_number").mes}
            />
            </div>
          </div>
          </Paper>
          <Paper elevation={0} className={classes.input_area}>
          <div className={classes.marginBottom_100px}>
            <div className={classes.input_item_text}>③受け取り用メールアドレス（必須）※gmailやyahoo Mail推奨</div> 
            <div className={classes.block}>
            <TextBox
              className={this.state.size.width > 500 ? classes.text_width_500px:classes.text_width_300px}
              id="mailaddress1"
              name="mail_address"
              placeholder="入力されたメールアドレス宛てにツールを配布致します。"
              validation={validation.mailaddress}
              onChange={this.handleChangeItem}
              value={this.state.userValue.find(item => item.target === "mail_address") === undefined ? '' : this.state.userValue.find(item => item.target === "mail_address").value}
              errorMessage={this.state.error_message.find(item => item.target === "mail_address") === undefined ? '' : this.state.error_message.find(item => item.target === "mail_address").mes}
            />
            </div>    
          </div>
          </Paper>          
          <Paper elevation={0} className={classes.input_area}>
          <div className={classes.marginBottom_100px}>
            <div className={classes.input_item_text}>④口座はMT4で作成していますか？</div> 
            <div className={classes.block}>
            <FormGroup className={classes.checkbox}>
              <FormControlLabel 
                control={<Checkbox />} 
                value="checkedYes"
                checked = {this.state.checked_yes}
                onChange={this.handleChangeCheck}
                label={<span className={classes.checkbox_label}>はい</span>}
              />
              <FormControlLabel 
                control={<Checkbox />} 
                value="checkedNo"
                checked = {this.state.checked_no}
                onChange={this.handleChangeCheck}
                label={<span className={classes.checkbox_label}>いいえ</span>}
              />
              <span className={classes.checkbox_label_no}> {this.state.checked_no === true ? 'MT4でないと動作しません。再度、口座の作成をお願い致します' : ''}</span>
            </FormGroup>
            </div>
          </div>  
          </Paper>   
          <Paper elevation={0} className={classes.input_area}>
          <div className={classes.marginBottom_100px}>
            <div className={classes.input_item_text}>⑤口座タイプはスタンダード口座で作成していますか？</div> 
            <div className={classes.block}>
            <FormGroup className={classes.checkbox}>
              <FormControlLabel 
                control={<Checkbox />} 
                value="checkedStandardYes"
                checked = {this.state.checked_standard_yes}
                onChange={this.handleChangeCheck}
                label={<span className={classes.checkbox_label}>はい</span>}
              />
              <FormControlLabel 
                control={<Checkbox />} 
                value="checkedStandardNo"
                checked = {this.state.checked_standard_no}
                onChange={this.handleChangeCheck}
                label={<span className={classes.checkbox_label}>いいえ</span>}
              />
              <span className={classes.checkbox_label_no}> {this.state.checked_standard_no === true ? 'スタンダード口座でないと正常に動作しません。再度、口座の作成をお願い致します' : ''}</span>
            </FormGroup>
            </div>
          </div>                    
          <div className={classes.button_area}>
            <Button className={classes.button_search} variant="outlined" color="primary" onClick={this.handleOpenConfirmAdd}>
                申請する
            </Button>

          </div>



        </Paper>
        </Paper>
        <Dialog
          open={this.state.confirmAdd}
          onClose={this.closeConfirmAdd}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps= {{
            classes: {
                root: classes.backDrop
            }
          }}
        >
          <DialogContent>
            <DialogContentText classes={{root: classes.input_item_text_send}} id="alert-dialog-description">
              この内容で送信しますか？
            </DialogContentText>
          </DialogContent>
          
          <DialogActions className={classes.dialog_actiton} >
            <Button className={classes.dialog_font} onClick={this.closeConfirmAdd} color="secondary">
              キャンセル
            </Button>
            <Button className={classes.dialog_font} onClick={this.handleCheckDB} color="primary" autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </div>


    );

    return (
      /*スマホ用
      <div className={Font.body}>
        <RequestFormSp/>
      </div>*/
      /*PC用*/
      <div className={Font.body}>
        {pcDrawer}
      </div>
      
    );
    
    
    }
}
// Material-ui関連
RequestFormCalculator.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };

export default withStyles(styles)(RequestFormCalculator);