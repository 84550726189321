import React, { Component } from "react";
import { Switch, Route,BrowserRouter as Router } from "react-router-dom";

//遷移先
import RequestForm from './containers/RequestForm';
import RequestFormEA from './containers/RequestFormEA';
import RequestFormEANT from './containers/RequestFormEANT';
import RequestFormCal from './containers/RequestFormCalculator';
import RequestFormCalTrial from './containers/RequestFormCalculatorTrial';
import RequestFormLeofx from './containers/RequestFormLeofx';
import Thanks from './containers/Thanks';
import ThanksEA from './containers/ThanksEA';
import ThanksEANT from './containers/ThanksEANT';
import ThanksEAHACHI from './containers/ThanksEAHACHI';
import ThanksLotTool from './containers/ThanksLotTool';
import ThanksLotToolTrial from './containers/ThanksLotToolTrial';
import SorryLotToolTrial from './containers/SorryLotToolTrial';
import Sorry from './containers/Sorry';
import ThanksPaidHachiForm from './containers/ThanksPaidHachiForm';
import ThanksLeoFX from './containers/ThanksLeoFX';
import ErrorPage from './containers/ErrorPage';
import DeveloperPage from './containers/DeveloperPage';
import RequestFormEAHACHI from './containers/RequestFormEAHACHI';
import RequestFormPaidHachi from './containers/RequestFormPaidHachi';






class App extends Component {
  render() {
    return (
          <Router>
            <Switch>
              {/*
                  フロントページ
              */}
              <Route exact path="/nst_tools" component={RequestForm} />
              <Route exact path="/ea_form" component={RequestFormEA} />
              <Route exact path="/ea_hachi" component={RequestFormEAHACHI} />
              
              <Route exact path="/ea_form_ninetails" component={RequestFormEANT} />
              <Route exact path="/hk_lottool" component={RequestFormCal} />
              <Route exact path="/trial_hk_lottool" component={RequestFormCalTrial} />
              <Route exact path="/leofxtool" component={RequestFormLeofx} />
              <Route exact path="/paidhachi" component={RequestFormPaidHachi} />

              <Route exact path="/thanks" component={Thanks} />
              <Route exact path="/thanksea" component={ThanksEA} />
              <Route exact path="/thankseaninetails" component={ThanksEANT} />
              <Route exact path="/thankseahachi" component={ThanksEAHACHI} />
              <Route exact path="/thankslottool" component={ThanksLotTool} />
              <Route exact path="/thankslottoolTrial" component={ThanksLotToolTrial} />
              <Route exact path="/thanksleofx" component={ThanksLeoFX} />
              <Route exact path="/thankspaidhachi" component={ThanksPaidHachiForm} />

              <Route exact path="/sorry" component={Sorry} />
              <Route exact path="/sorrylottoolTrial" component={SorryLotToolTrial} />

              <Route exact path="/errorPage" component={ErrorPage} />
              <Route exact path="/developerPage" component={DeveloperPage} />

              {/*
                  Infomation
              */}

              {/*
                  お問合せフォーム
              */}
              {/*テンプレート*/}


            </Switch>
          </Router>
    );
  }
}

//export default withAuthenticator(App);
export default App;
